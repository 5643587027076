<template>
    <div class="files-list-box wrapperForm">
        <v-row dense style="margin-bottom:0!important;">
            <v-col cols="auto" class="section-wrapper attachments-left-menu">
                <v-card
                    class="cloud-file-list-box"
                    flat
                    width="300"
                >
                    <v-card-text>
                        <div class="form-box-title title-with-right-part alm-title">
                            <span>{{$t('Вложения')}}</span>
                            <div class="twrp-content">
                                <v-chip 
                                    v-show="isDownloadAllVisible"
                                    class="table-label"
                                    small
                                    label
                                    color="teal"
                                    dark
                                    @click="loadAllAttchments"
                                >
                                    <div class="d-flex align-center">
                                        <v-icon>fas fa-file-download</v-icon>
                                        {{ $t("Скачать_все") }}
                                    </div>
                                </v-chip>
                            </div>
                        </div> 
                        <div class="cloud-items-container">
                            <div v-if="!groupedAttachments.length" class="cfl-placeholder" >{{$t('Нет_файлов')}}</div>
                            <v-row 
                                v-else 
                                v-for="group in groupedAttachments"
                                :key="group[0]"
                                class="cfl-group-wrap"
                                no-gutters
                            >
                                <v-col cols="12" sm="12" md="12" v-if="groupedAttachments.length > 1" class="cfl-group-title">{{getGroupName(group[0])}}</v-col>
                                <v-col cols="12" sm="12" :md=" groupedAttachments.length > 1 ? '12' : '12'" class="cfl-group-items">
                                    <v-row dense>
                                        <v-col 
                                            cols="12" sm="12" md="12"
                                            class="cfl-item-wrap"
                                            v-for="attachment in group[1]" 
                                            :key="attachment.Id"
                                        >
                                            <div 
                                                class="cfl-item"
                                                :class="{ 'active-cfl': attachment.IsActive }"
                                                @click="onAttachmnentClick(attachment)"
                                            >
                                                <div 
                                                    v-if="attachment.Progress"
                                                    class="cfl-item-progress"
                                                    :style="{ width: attachment.Progress }"
                                                >
                                                </div>
                                                <div class="file-icon">
                                                    <!--Для добавления стикера сертификата на иконку добавляем у тега <i> к существующим классам класс with-cert и еще второй класс для управления цветом success-cert или danger-cert -->
                                                    <v-icon :color="getIconColor(attachment.Extension)">
                                                        {{ getIcon(attachment.Extension) }}
                                                    </v-icon>
                                                </div>
                                                <div class="cfl-info">
                                                    <div class="cfl-abs" v-tooltip.left-center="getAttachmentName(attachment)">
                                                        <div class="cfl-filename">{{ getAttachmentName(attachment) }}</div>
                                                        <div>
                                                            <div class="cfl-fileinfo">
                                                                <div v-if="attachment.Message" class="cfl-err-msg">{{ attachment.Message }}</div>
                                                                <span v-else-if="attachment.Progress">{{ attachment.Progress }}</span>
                                                                <span v-else>{{ parseInt(attachment.Length) ? `${(parseFloat(attachment.Length) / (1024 * 1024)).toFixed(2)} ${$t('МБ')}` : '-' }}</span>                                                
                                                            </div>
                                                            <!-- <div class="cfl-status"></div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cfl-actions"
                                                    v-if="!attachment.Progress"
                                                >
                                                    <span 
                                                        v-tooltip.left-center="$t('Информация_о_файле')"
                                                        @click="openInfoModal(attachment)"
                                                    >
                                                        <i class="fas fa-info-circle dark-gray-color"></i>
                                                    </span>
                                                    <span 
                                                        v-if="!attachment.Message && !attachmentIsReportTemplate(attachment)"
                                                        class="download-file" 
                                                        v-tooltip.left-center="$t('Загрузить')"
                                                        @click.stop="loadAttchment(attachment)"
                                                    >
                                                        <i class="fas fa-download dark-gray-color"></i>
                                                    </span>
                                                    <span
                                                        v-if="!attachment.IsReadOnly && !attachmentIsReportTemplate(attachment) && ( !isDocument || isExistedDocument ) && !isDocumentIsArchive" 
                                                        class="delete-file"
                                                        v-tooltip.left-center="$t('Удалить')"
                                                        @click.stop="deleteAttchment(attachment)"
                                                    >
                                                        <i class="fas fa-trash dark-gray-color"></i>
                                                    </span> 
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <div class="cfl-break"></div>
                        </div> 
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="section-wrapper">
                <v-card
                    v-if="!hidePreviewWarningMessage"
                    class="file-preview-attention-card"
                    flat
                >
                    <v-card-text>
                        <div class="form-box-title attention-box-title fbt-slider-title">
                            <span>{{ $t("Внимание") }}</span>
                            <div class="fbt-slider">
                                <div class="prev-title">{{ $t('Больше_не_показывать') }}</div>
                                <v-switch 
                                    class="cust-switch" 
                                    inset 
                                    hide-details
                                    v-model="hidePreviewWarningMessage"
                                ></v-switch>
                            </div>
                        </div>
                        <v-row no-gutters>
                            <v-col  cols="12" sm="12" md="12">
                                <div class="onlyReadData">
                                    {{ $t('Предпросмотр_предупреждение') }}   
                                </div>
                            </v-col>
                        </v-row>                        
                    </v-card-text>
                </v-card>
                <v-card 
                    class="file-preview"
                    v-show="isPreviewVisible"
                    flat
                >
                    <v-card-text>
                        <div class="form-box-title fbt-slider-title file-preview-box-title">
                            <span>{{ $t("Предпросмотр") }} {{ activeItem ? '(' + attachmentName + ')': '' }}</span>
                            <div class="fbt-slider" v-if="isPaperCopyDocVisible">
                                <div class="prev-title">{{$t('Бумажная_копия_документа')}}</div>
                                <v-switch 
                                    v-model="isPaperCopyDoc" 
                                    class="cust-switch" 
                                    inset 
                                    hide-details
                                ></v-switch>
                            </div>
                        </div>
                        <v-progress-linear
                            v-show="isPreviewLoading"
                            height="2" 
                            color="#E43958" 
                            indeterminate
                        >
                        </v-progress-linear>
                        <div id="PDFPreview" class="file-preview-iframe">
                            <div class="cfl-placeholder" v-if="noPreviewMessage">{{ noPreviewMessage }}</div>
                            
                            <template v-else-if="activeItem">
                                <iframe
                                    :key="activeItem.Id"
                                    frameBorder="0"
                                    :src="previewSrc"
                                >
                                </iframe>
                            </template>
                            
                            <div v-else class="cfl-placeholder" >{{$t('Вложения_не_выбраны')}}</div>

                        </div>
                    </v-card-text>
                </v-card>
            </v-col>    
        </v-row>

        <FileInfoDlg ref="FileInfoDlgRef" />
    </div>
</template>

<script>
import FileInfoDlg from '@/components/global/dialogs/FileInfoDlg';
import Axios from 'axios';
import { httpAPI, errorHandler } from "@/api/httpAPI";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import sys from '@/services/system';

export default {
    name: "attachments-tab",
    components: {
        FileInfoDlg,
    },
    get groupNames() {
        return [
            "Прочие",            
            "Входящие",            
            "Уведомления_на_оплату",            
            "Уведомления_на_подписание",            
            "Ответ", 
            "Дополнительно"          
    ]},
    get supportedFormats() { 
        return [
            ".PDF",
            ".JPG",
            ".JPEG",
            ".PNG",
            ".DOC",
            ".DOCX",
            ".XLS",
            ".XLSX",
            ".PPT",
            ".PPTX",
            ".RTF",
            ".TXT",
            ".XML",
            ".ODT",
            ".ODS",
            ".ODP",
            ".CSV"
        ]
    },
    fileExtensionsAssociationIcons: {
        pdf: 'fas fa-file-pdf',
        jpg: 'fas fa-file-image',
        jpeg: 'fas fa-file-image',
        png: 'fas fa-file-image',
        doc: 'fas fa-file-word',
        docx: 'fas fa-file-word',
        xls: 'fas fa-file-excel',
        xlsx: 'fas fa-file-excel',
        rar: 'fas fa-file-archive',
        zip: 'fas fa-file-archive',
        '7z': 'fas fa-file-archive',
        ppt: 'fas fa-file-powerpoint',
        pptx: 'fas fa-file-powerpoint',
        csv: 'fas fa-file-csv',
        odt: 'fas fa-file-word',
        rtf: 'fas fa-file-word',
        ods: 'fas fa-file-excel',
        txt: 'fas fa-file-alt'
    },
    fileExtensionsAssociationIconsColors: {
        pdf: '#ba141a',
        jpg: '#662d91',
        jpeg: '#662d91',
        png: '#662d91',
        doc: '#0054a6',
        docx: '#0054a6',
        xls: '#008641',
        xlsx: '#008641',
        ppt: '#f04e23',
        pptx: '#f04e23',
        csv: '#008641',
        ods: '#008641',
        odt: '#0054a6',
        rtf: '#0054a6',
        txt: '#0054a6',
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            noPreviewMessage: null,
            isPreviewVisible: true,
            isPreviewLoading: false,
            previewBlobs: [],
            attachmentName: '',
            isPaperCopyDoc: false,
            isPaperCopyDocVisible: false,
            previewIsPaperCopy: [],
            forceUpdate: 0,
            prevAttchName: ''
        }
    },
    computed: {
        ...mapGetters('global/actionsource', { documentSource: 'getDataSource', isDocumentIsArchive: 'isDocumentIsArchive', dataSourceType: 'getDataSourceType' }),
        ...mapGetters('global/attachments', { dataSource: 'getDataSource', previewSrc: 'getPreviewSrc' }),
        groupedAttachments() {
            let groupedAttachments = this.$_.groupBy(this.dataSource, (item) => {
                return item.Group;
            });
            
            Object.keys(groupedAttachments).forEach((key) => {            
                groupedAttachments[key] = groupedAttachments[key].map(x => x).sort(function (a, b) { return a.Name.localeCompare(b.Name); });
            });

            return Object.entries(groupedAttachments);
        },
        activeItem() {
            return this.dataSource?.find(i => i.IsActive);
        },
        activeItemInfo() {
            return {
                Version: this.activeItem?.Version ?? '-',
                Author: this.activeItem?.Author ?? '-',
                IsReadOnly: this.activeItem != null ? this.activeItem.IsReadOnly ? this.$t("Да") : this.$t("нет") : '-',
                RecDate: this.activeItem?.RecDate ?? '-',
                Description: this.activeItem?.Description
            }
        },
        isDownloadAllVisible() {
            if (this.dataSourceType === 'ReportQuery'){
                return this.dataSource.filter(item => item.Id != this.documentSource.Data.Object.Document.SchemaAttachmentId && item.Id != this.documentSource.Data.Object.Document.TemplateAttachmentId).length > 1;
            }
            else 
                return this.dataSource?.length > 1;
        },
        isDocument() {
            return this.documentSource?.Data?.Object?.Document != null;
        },
        isExistedDocument() {
            return this.documentSource?.Data?.Object?.Document?.is_new_record === false;
        },
        hidePreviewWarningMessage: {
            get: function() {
                this.forceUpdate;
                return JSON.parse(localStorage.getItem('PREVIEWS_WARN_MSG_HIDDEN') ?? 'false')
            },
            set: function(v) {
                localStorage.setItem('PREVIEWS_WARN_MSG_HIDDEN', v);
                this.forceUpdate++;
            }
        }
    },
    mounted() {
        this.$emit('onMounted');
        this.$nextTick(this.heightControlMethod);
        window.addEventListener('resize', this.heightControlMethod);
    },
    created() {
        this.update();
        this.$emit('onCreated');
    },
    destroyed() {
        this.previewBlobs.forEach(x => window.URL.revokeObjectURL(x.url));
        this.previewBlobs = [];                    
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.heightControlMethod);
    },
    watch: {
        isPreviewVisible(val) {
            if (val && this.activeItem)
                this.loadPreview(this.activeItem.Id)
        },
        isPaperCopyDoc(v) {
            if (v != this.previewIsPaperCopy.includes(this.activeItem.Id)){
                let item = this.previewBlobs.find(x => x.id == this.activeItem.Id);
                this.previewBlobs.splice(this.previewBlobs.indexOf(item), 1);
                this.$nextTick(function() {
                    this.loadPreview(this.activeItem.Id);
                });
            }
        },
        hidePreviewWarningMessage() {
            this.$nextTick(function() {
                this.heightControlMethod();
            });
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('global/actionsource', ['setActiveTabName']),
        ...mapActions('global/attachments', ['setCurrentDocumentId', 'updateDataSource', 'deleteAttchment', 'setPreviewSrc', 'setDataSource']),
        ...mapMutations('global/attachments', { setDataSource: 'SET_DATASOURCE' }),
        hashCode: sys.hashCode,
        b64toBlob (b64Data, contentType='', sliceSize=512) {
            let byteCharacters = atob(b64Data);
            let byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                let slice = byteCharacters.slice(offset, offset + sliceSize);

                let byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: contentType });
        },
        async update() {
            if (this.$parent.isActive)
                this.setActiveTabName('attachments-tab');

            this.setDataSource({ dataSource: [] });
            this.setOverlayVisible({ visible: true });
            await this.setCurrentDocumentId(this.id);
            await this.updateDataSource();
            this.setOverlayVisible({ visible: false });            
        },
        attachmentIsReportTemplate(item) {
            return this.dataSourceType === 'ReportQuery' && (item.Id == this.documentSource.Data.Object.Document.SchemaAttachmentId || item.Id == this.documentSource.Data.Object.Document.TemplateAttachmentId);
        },
        onAttachmnentClick(item) {
            if (item.Progress || item.Message || item.IsActive)
                return;
                
            let tmp = Array.from(this.dataSource);
            tmp.forEach(element => {
                element.IsActive = element.Id == item.Id;
            });
            this.setDataSource({ dataSource: tmp });
            this.isPaperCopyDoc = this.previewIsPaperCopy.includes(item.Id);
            if (this.isPreviewVisible) {
                this.loadPreview(this.activeItem.Id);
            }
        },
        async loadAttchment(item) {
            this.$eventBus.$emit('on-download-file', { id: item.Id, name: item.Name, });
        },        
        async loadAllAttchments() {
            let type = "doc";

            if (this.dataSourceType === 'Order')
                type = "order";

            if (this.dataSourceType === 'Meeting')
                type = "meeting";
            
            // формируем URL
            let request = httpAPI({ url: `api/download/attachments?id=${this.id}&type=${type}`, method: 'GET', responseType: 'blob', skipErrorHandler: true });
            
            // при успешном выполнении запроса на формирование отчёта выполнить:
            var onResolve = (response) => {
                this.$notify.success(`Загрузка файла ${name} завершена`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Attachments.zip');
                document.body.appendChild(link);
                link.click();
            };
            // при неудачном выполнении запроса на формирование отчёта выполнить:
            var onReject = (error) => {
                errorHandler(error);
            };

            let asyncReportToastMessage = `Загрузка вложений`;
            this.$notify.async(request, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });
        },
        async loadPreview(id) {
            if (!id) return;
            let attachment = this.dataSource.find(i => i.Id == id);
            this.attachmentName = attachment.Name;
            this.isPaperCopyDocVisible = 
                ['.DOCX', '.DOC'].includes(attachment.Extension.toUpperCase())
                && this.dataSourceType == "OutgoingDocument";

            if (attachment && !this.$options.supportedFormats.includes(attachment.Extension.toUpperCase())) {
                this.setPreviewSrc("");
                this.noPreviewMessage = this.$t('Предпросмотр_для_данного_типа_файла_невозможен!');
                return;               
            }

            this.noPreviewMessage = null;
            this.isPreviewLoading = true;
            this.setPreviewSrc("");
            let found = this.previewBlobs.find(x => x.id == id);
            
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel(`$Предпросмотр_вложения_<${this.prevAttchName}}>_отменен.message`);

            this.prevAttchName = attachment.Name;

            if (found != undefined) {
                this.setPreviewSrc(found.url);
            }
            else {      

                this.cancellationTokenSorce = Axios.CancelToken.source();

                // LibreOffice convertion
                let url = `api/download/preview?id=${id}&extension=${attachment.Extension.toUpperCase()}&colontitules=${this.isPaperCopyDoc}`;
                
                let response = await httpAPI({
                    url: url,
                    method: 'GET',
                    headers: { 'isCommon': this.$store.getters['global/actionsource/isDataSourceCommon'], 'isMeeting': this.dataSourceType === 'Meeting' },
                    skipErrorHandler: true,
                    cancelToken: this.cancellationTokenSorce.token,
                });
                
                if (!response || this.activeItem?.Id != id)
                    return;

                //response - это ответ со строкой base64
                if (response.status === 200 && response.data.Success) {
                    try
                    {
                        let type = '';

                        switch (attachment.Extension.toUpperCase())
                        {
                            case ".JPG":
                            case ".JPEG":
                                type = "image/jpg";
                            break;

                            case ".PNG":
                                type = "image/png";
                            break;

                            default:
                                type = "application/pdf";
                                break;
                        }

                        let url = window.URL.createObjectURL( this.b64toBlob(response.data.Payload, type) ) + '#view=fitH'; //URL с указанием на объект (всегда короткий), внутри данные

                        this.previewBlobs.push({ id, url }); //оптимизация, при повторном открытии предпросмотра не будем скачивать
                        let idx = this.previewIsPaperCopy.indexOf(id);

                        if (this.isPaperCopyDoc) {
                            if (idx < 0)
                                this.previewIsPaperCopy.push(id);
                        }
                        else {
                            if (idx >= 0)
                                this.previewIsPaperCopy.splice(idx, 1);
                        }
                        
                        let tmp = Array.from(this.dataSource);
                        this.setDataSource({ dataSource: tmp });
                        this.setPreviewSrc(url);
                    }
                    catch(err)
                    {
                        console.log("Ошибка подготовки предпросмотра: ", err);
                    }
                }
                else
                {
                    this.$notify.alert(response.data.Message);
                    this.noPreviewMessage = this.$t('Предпросмотр_для_данного_файла_невозможен!');
                }
            }

            this.isPreviewLoading = false;
        },
        addAttachment() {
            this.$refs.inputFile.click()
        },
        getGroupName(v) {
            return this.$options.groupNames[v] ? this.$t(this.$options.groupNames[v]) : '';
        },
        async openInfoModal(attachment) {
            try
            {
                this.onAttachmnentClick(attachment);
                await this.$refs.FileInfoDlgRef.open({ ParentType: this.$store.getters['global/actionsource/getDataSourceType'], ...attachment });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        getAttachmentName(attachment) {

            if (this.$store.getters['global/actionsource/getDataSourceType'] === 'IQalaStatement' && attachment.Description != null && typeof attachment.Description === 'string' && attachment.Description.length > 0)
                return attachment.Description;

            return attachment.Name;
        },
        getIcon(fileExtension) {

            if (fileExtension != null && typeof fileExtension === 'string' && fileExtension.length > 0) {
                return this.$options.fileExtensionsAssociationIcons[fileExtension.replace('.', '')] ?? 'fas fa-file';
            }

            return 'fas fa-file';
        },
        getIconColor(fileExtension) {

            if (fileExtension != null && typeof fileExtension === 'string' && fileExtension.length > 0) {
                return this.$options.fileExtensionsAssociationIconsColors[fileExtension.replace('.', '')] ?? '#b4b4b4';
            }

            return '#b4b4b4';
        },
        heightControlMethod() {
            var parent = document.getElementsByClassName('content-box')[0];
            var top_btns_bar_obj = parent.getElementsByClassName('top-btns-bar');
            var white_panel_obj = parent.getElementsByClassName('white-panel-items');
            var tab_selector_panel_obj = parent.getElementsByClassName('tab-selector-panel');
            var after_tabs_card_btn_obj = parent.getElementsByClassName('after-tabs-part-wrapper');
            var alm_title_obj = parent.getElementsByClassName('alm-title');
            var cloud_items_container_obj = parent.getElementsByClassName('cloud-items-container');
            var file_preview_attention_card_obj = parent.getElementsByClassName('file-preview-attention-card');
            var file_preview_box_title_obj = parent.getElementsByClassName('file-preview-box-title');
            var file_preview_iframe_obj = parent.getElementsByClassName('file-preview-iframe');
            
            var parent_h = parent.clientHeight;
            var toolbar_h = top_btns_bar_obj.length > 0 ? top_btns_bar_obj[0].clientHeight : 0;
            var white_panel_h = white_panel_obj.length > 0 ? white_panel_obj[0].clientHeight : 0;
            var tabs_margin_top = white_panel_obj.length > 0 ? 10 : 0;
            var after_tabs_card_btn_h = after_tabs_card_btn_obj.length > 0 ? after_tabs_card_btn_obj[0].clientHeight : 
            tab_selector_panel_obj[0].clientHeight;
            var alm_title_h = alm_title_obj.length > 0 ? alm_title_obj[0].clientHeight : 0;
            var file_preview_attention_card_h = file_preview_attention_card_obj.length > 0 ? file_preview_attention_card_obj[0].clientHeight + 16 : 0;
            var file_preview_box_title_h = file_preview_box_title_obj.length > 0 ? file_preview_box_title_obj[0].clientHeight : 0;
            //растягиваем меню выбора и области просмотра для вложений на высоту экрана    
            //для меню слева
            cloud_items_container_obj[0].style.height = (parent_h - (toolbar_h + white_panel_h + tabs_margin_top + after_tabs_card_btn_h + alm_title_h + 80)) + "px";
            //для iframe предпросмотра
            file_preview_iframe_obj[0].style.height = (parent_h - (toolbar_h + white_panel_h + tabs_margin_top + after_tabs_card_btn_h + file_preview_box_title_h + file_preview_attention_card_h + 80)) + "px";
        }
    }
}
</script>


